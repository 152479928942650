<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-left dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-flex user-nav align-items-left">
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.fullName"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
        <div class="user-info d-sm-block d-none text-white">
          <p class="user-name font-weight-bolder mb-0">{{ userData.fullName || userData.username }}</p>
          <span class="user-role">{{ userData.role }}</span>
        </div>
      </div>
    </template>
    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar } from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { getAuth, signOut } from 'firebase/auth'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    logout() {
      const auth = getAuth()
      signOut(auth)
        .then(() => {
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
          localStorage.removeItem('userData')
          this.$ability.update(initialAbility)
          this.$router.push({ name: 'auth-login' })
        })
        .catch((error) => {
          alert(error.message)
        })
    },
  },
}
</script>

<style scoped>
.dropdown-user-link {
  cursor: pointer;
}

.user-nav {
  margin-right: 10px;
}

.badge-minimal {
  position: relative;
}

.user-info {
  color: white;
}

.user-name {
  margin-bottom: 0;
}

.user-role {
  font-size: 0.8rem;
}
</style>
