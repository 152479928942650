export default [
  {
    header: 'Company Analysis',
    action:'read',
    resource: 'ACL'
  },
  {
    title: 'Company Analysis',
    route: 'Company Analysis',
    icon: 'SearchIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Screener',
    route: 'Screener',
    icon: 'CompassIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Buffett-Screener',
        route: 'Buffett-Screener',
         // acl: {
        action: 'read',
        resource: 'ACL',
         // },
      },
      {
        title: 'Lynch-Screener',
        route: 'Lynch-Screener',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Graham-Screener',
        route: 'Graham-Screener',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Custom-Screener',
        route: 'Custom-Screener',
        action: 'read',
        resource: 'ACL',
      },
    ],
    // },
  },
  // {
  //   title: 'Overview',
  //   route: 'Overview',
  //   icon: 'SearchIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  // {
  //   title: 'Financial-Strength',
  //   route: 'Financials',
  //   icon: 'FileIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  // {
  //   title: 'Growth',
  //   route: 'Growth',
  //   icon: 'BarChartIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  // {
  //   title: 'Profitability',
  //   route: 'Profitability',
  //   icon: 'PieChartIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  // {
  //   title: 'Valuation',
  //   route: 'Valuation',
  //   icon: 'DollarSignIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   // },
  // },
  {
    header: 'Market Analsyis',
    action:'read',
    resource: 'ACL'
  },
  {
    title: 'Artificial Intelligence',
    route: 'Portfolio-Builder',
    icon: 'CpuIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Sentiment-Analysis',
    route: 'Sentiment-Analysis',
    icon: 'FileTextIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
  
      action: 'read',
      subject: 'notSigned',
    
    // },
  },
  {
    header: 'Personal Area',
    action:'read',
    resource: 'ACL'
  },
  {
    title: 'Watchlist',
    route: 'Watchlist',
    icon: 'BookmarkIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Portfolio',
    route: 'Portfolio',
    icon: 'PieChartIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    header: 'Community',
    action:'read',
    resource: 'ACL'
  },
  {
    title: 'Feedback',
    route: 'Feedback',
    icon: 'MessageSquareIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Menu Levels',
    icon: 'MenuIcon',
    children: [
      {
        title: 'Menu Level 2.1',
        route: null,
      },
      {
        title: 'Menu Level 2.2',
        children: [
          {
            title: 'Menu Level 3.1',
            route: null,
          },
          {
            title: 'Menu Level 3.2',
            route: null,
          },
        ],
      },
    ],
  },
  {
    title: 'Disabled Menu',
    route: null,
    icon: 'EyeOffIcon',
    disabled: true,
  },
  {
    title: 'Raise Support',
    href: 'https://pixinvent.ticksy.com/',
    icon: 'LifeBuoyIcon',
  },
  {
    title: 'Documentation',
    href: 'https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation',
    icon: 'FileTextIcon',
  },
]
